import { mapActions, mapGetters } from 'vuex';
import LoggedUserMixin from '@/mixins/logged-user';
const localContentStepperMixin = {
    computed: {
        ...mapGetters({
            getToReturn: 'localContent/getToReturn',
            getPlanInfos: 'localContent/getPlanInfos',
            getPlanList: 'localContent/getPlanList',
            getSalariesQuestionsListMock: 'localContent/getSalariesQuestionsListMock',
            getSalariesAnswersListMock: 'localContent/getSalariesAnswersListMock'
        }),
    },
    mixins:[LoggedUserMixin],
    watch: {
        getPlanList: {
            handler(val) {
                if (val) {
                    this.handleFetchPlanInfos();
                }
            },
            deep: true
        },
        getPlanInfos: {
            handler(val) {
                if (val) {
                    this.setRefactoList();
                }
            },
            deep: true
        },
        getSalariesQuestionsListMock(newJobsQuestions, oldJobsQuestions) {
            this.salariesQuestionsListMock = newJobsQuestions;
            console.log(oldJobsQuestions);
        },
        getSalariesAnswersListMock(newJobsAnswers, oldJobsAnswers) {
            console.log(oldJobsAnswers);
            this.salariesAnswersListMock = newJobsAnswers;
            this.setRefactoList();
            console.log('ref', this.refactoListAnswers);
        },
    },
    async mounted() {
        if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {this.fetchPlans({params: `companyName=${encodeURIComponent(this.$route.params.companyName.toLowerCase())}`})}else{this.fetchAllSalaries()}
        // console.log(this.getPlanList?.data[0]?.id);
    },
    methods: {
        ...mapActions({
            selectToReturn: 'localContent/selectToReturn',
            fetchPlans: 'localContent/fetchPlans',
            fetchAllSalaries: 'localContent/fetchAllSalaries',
            submitAnswers: 'localContent/submitAnswers',
            fetchPlanInfos: 'localContent/fetchPlanInfos',
        }),
        handleFetchPlanInfos() {
            // alert(this.getPlanList?.data[0]?.id);
            this.fetchPlanInfos({ params: this.getPlanList?.data[0]?.id });
        },
        setRefactoList() {
                this.refactoListAnswers = [];
                this.adminrefactoListAnswers = [];

                let planInfosAnswers= this.getPlanInfos?.answers;
                let planInfosQuestions= this.getPlanInfos?.questions;
                // console.log(this.getPlanInfos,",,,,",planInfosQuestions)
                //BUILD LIST OF ANSWERS FROM QUESTIONS LIST
                if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {
                    planInfosQuestions.forEach((element) => {
                        let hasAnswer = [];
        
                        hasAnswer = planInfosAnswers.filter((e) => {
                            return e.questionId === element.id;
                        })?.[0];

                        if(element.heading === "SALARIES"){
                            this.adminrefactoListAnswers.push({
                                answers: {
                                    unit:element.unit,
                                    ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
                                    questionId: element.id,
                                    ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
                                    forecast: (hasAnswer?.forecast >= 0)? hasAnswer?.forecast : null,
                                    real: (hasAnswer?.real >= 0)? hasAnswer?.real : null,
                                    comment: hasAnswer?.comment || '',
                                },
                                title: element.title,
                                year: 2023,
                            });
                        }

                        
                    });
                }else{
                    this.salariesQuestionsListMock.forEach((element) => {

                        let hasAnswer = [];
        
                        hasAnswer = this.salariesAnswersListMock.filter((e) => {
                            return e.questionId === element.id;
                        })?.[0];

                    this.refactoListAnswers.push({
                        answers: {
                            unit:element.unit,
                            ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
                            questionId: element.id,
                            ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
                            forecast: (hasAnswer?.forecast >= 0)? hasAnswer?.forecast : null,
                            real: (hasAnswer?.real >= 0)? hasAnswer?.real : null,
                            comment: hasAnswer?.comment || '',
                        },
                        title: element.title,
                        year: 2023,
                    });
                });
                }
        },
        saveAnswers() {
            let Obj = {
                answers: [],
                year: 2023,
            };
            this.refactoListAnswers.forEach((element) => {
                Obj.answers.push(element.answers);
            });

            // if (!this.isAllValuesDefined()) {
            //     this.$toast.add({
            //         severity: 'error',
            //         summary: 'Mise à jour',
            //         detail: 'Mise à jour effectuée non efféctués',
            //         life: 7000,
            //     });
            // } else {
            //     this.submitAnswers(Obj).then((e) => {
            //         this.$toast.add({
            //             severity: 'success',
            //             summary: 'Mise à jour',
            //             detail: 'Mise à jour effectuée avec success',
            //             life: 7000,
            //         });
            //         console.log(e);
            //     });
            // }
            this.submitAnswers(Obj).then((e) => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Mise à jour',
                    detail: 'Mise à jour effectuée avec success',
                    life: 7000,
                });
                console.log(e);
            });

        },
        isAllValuesDefined() {
            let invalidQId = this.refactoListAnswers.filter(e => { return e.answers.forecast === null }).map(function (e) {
                return e.answers.questionId;
            });
            console.log((invalidQId.length === 0));

            if (invalidQId.length === 0) {
                this.invalidQuestionIds = []
            } else {
                this.invalidQuestionIds = invalidQId;
            }

            if (this.invalidQuestionIds.length === 0) return true;
            return false;
        },
        async gotoSalaryStep() {
            if (!this.isAllValuesDefined()) return;
            // console.log(this.invalidQuestionIds == []);
            if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {
                console.log(this.getPlanList?.data[0]);
            }else{   
                
                this.saveAnswers();
            }
            this.$router.push(`/local-content/step/${this.getPlanList?.data[0]?.entreprise.name}/salary`);
        },

        closeEditModal() {
            this.displayEditModal = false;
        },
        gotoJobStep(){
          this.$router.push(`/local-content/step/${this.getPlanList?.data[0]?.entreprise.name}`);
        },
            gotoTrainingStep() {
                if (!this.isAllValuesDefined()) return;
                // console.log(this.invalidQuestionIds == []);
                if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {
                    console.log(this.getPlanList?.data[0]);
                }else{   
                    
                    this.saveAnswers();
                }
                this.$router.push(`/local-content/step/${this.getPlanList?.data[0]?.entreprise.name}/training`);
  },
        getAnswer(id) {
            const data = this.refactoListAnswers.filter((e) => e.answers.questionId === id)[0]?.answers;
            return data;
        },
        formatCurrency(value) {
            return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
        },
    },
};

export default localContentStepperMixin;
